import React from 'react'
import { useHistory } from 'react-router-dom'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

import Button from '../../atoms/Button'
import Input from '../../atoms/Input'
import DiscordIcon from '../../atoms/DiscordIcon'
import Layout from '../../templates/Layout'

import BgImg from '../../assets/img/bg2.png'
import RatingImg from '../../assets/img/rating.png'

import './SellPage.styl'

const apiEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT
const accessToken = process.env.REACT_APP_PRISMIC_TOKEN

const Client = Prismic.client(apiEndpoint, { accessToken })

class SellPage extends React.Component {
  state = {
    doc: null
  }
  fetchData = async () => {
    const response = await Client.query(Prismic.Predicates.at('document.type', 'sell-page'))
    if (response) {
      this.setState({
        ...this.state,
        doc: response.results[0].data
      })
    }
  }
  componentDidMount() {
    this.fetchData()
    const hero = document.querySelector('.sell-page')
    const heroDimensions = hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: heroDimensions?.height + 200,
      cellSize: 50,
      variance: 0.4,
      xColors: 'Blues'
    })
    const x = document.querySelector('.sell-page')
    if (x) {
      x.appendChild(pattern.toSVG())
    }

    window.onresize = () => {
      if (!window.trianglify) return
      const hero = document.querySelector('.sell-page')
      const heroDimensions = hero?.getBoundingClientRect()
      const pattern = window?.trianglify({
        width: heroDimensions?.width,
        height: heroDimensions?.height + 200,
        cellSize: 40,
        variance: 0.2,
        xColors: 'Blues'
      })
      const x = document.querySelector('.sell-page svg')
      if (x) {
        document.querySelector('.sell-page svg').remove()
      }
      const y = document.querySelector('.sell-page')
      if (y) {
        document.querySelector('.sell-page').appendChild(pattern.toSVG())
      }
    }
  }

  render() {
    const { doc } = this.state
    return (
      <Layout>
        <div className='sell-page' style={{ height: '100vh', width: '100vw' }}>
          <div className='sell-page__main'>
            <h1>{RichText.asText(doc?.title)}</h1>
            <h5>{RichText.asText(doc?.subheader)}</h5>
            <img src={doc?.banner_img?.url} />
            <p>{RichText.asText(doc?.description)}</p>
            <form action='https://formspree.io/f/xoqprvbn' method='POST' onSubmit={e => null}>
              <span>Name </span> <br />
              <Input type='text' name='name' />
              <br />
              <span>Email </span> <br />
              <Input type='text' name='_replyto' />
              <br />
              <span>Message </span> <br />
              <Input type='text' name='message' />
              <br />
              <Button className='primary-btn' type='submit'>
                {RichText.asText(doc?.btn_text)}
              </Button>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SellPage
