import React, { useState, useEffect } from 'react'
import { Slide } from 'react-slideshow-image'
import { SRLWrapper } from 'simple-react-lightbox'
import { useHistory } from 'react-router-dom'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

import Button from '../../atoms/Button'
import Input from '../../atoms/Input'
import DiscordIcon from '../../atoms/DiscordIcon'
import Layout from '../../templates/Layout'

import './HomePage.styl'
import 'react-slideshow-image/dist/styles.css'

const urls = [
  'https://i1.wp.com/rockymountaincollectibles.com/wp-content/uploads/2020/12/play-area.jpg?w=960&ssl=1',
  'https://i2.wp.com/rockymountaincollectibles.com/wp-content/uploads/2020/12/%C2%A9-Devoted-Eye-Photography-06696.jpg?resize=2048%2C1365&ssl=1',
  'https://i1.wp.com/rockymountaincollectibles.com/wp-content/uploads/2020/12/%C2%A9-Devoted-Eye-Photography-04897.jpg?resize=2048%2C200&ssl=1',
  'https://i2.wp.com/rockymountaincollectibles.com/wp-content/uploads/2020/12/%C2%A9-Devoted-Eye-Photography-06701.jpg?resize=2048%2C1365&ssl=1',
  'https://i0.wp.com/rockymountaincollectibles.com/wp-content/uploads/2020/12/GEDC0116.jpg?resize=600%2C450&ssl=1'
]

const gradedUrls = [
  'https://i2.wp.com/rockymountaincollectibles.com/wp-content/uploads/2021/01/20210101_122236-rotated-e1609544421777.jpg?fit=618%2C1024&ssl=1',
  'https://i2.wp.com/rockymountaincollectibles.com/wp-content/uploads/2021/01/20210101_120047-rotated-e1609537632427.jpg?fit=641%2C1024&ssl=1',
  'https://i1.wp.com/rockymountaincollectibles.com/wp-content/uploads/2020/12/20201211_164525-rotated-e1609115674108.jpg?fit=603%2C1024&ssl=1',
  'https://i2.wp.com/rockymountaincollectibles.com/wp-content/uploads/2020/12/GEDC0155-rotated-e1609113252640.jpg?fit=599%2C1024&ssl=1'
]

const logos = {
  yugioh:
    'https://i2.wp.com/fullsync.co.uk/wp-content/uploads/2019/07/Yu-Gi-Oh.gif?fit=788%2C350&ssl=1',
  pokemon:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Pok%C3%A9mon_Trading_Card_Game_logo.svg/1200px-Pok%C3%A9mon_Trading_Card_Game_logo.svg.png',
  pop:
    'https://www.funko.com/craftmin/users/pop-yourself-logo-089274fc4402d61e99429deb208b7f33.png',
  digimon: 'https://cdn.chaoscards.co.uk/uploads/prod_img/2_164474_e.png?v=-62169983925'
}

const apiEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT
const accessToken = process.env.REACT_APP_PRISMIC_TOKEN

const Client = Prismic.client(apiEndpoint, { accessToken })

const HomePage = props => {
  const [doc, setDocData] = React.useState(null)

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(Prismic.Predicates.at('document.type', 'homepage'))
      if (response) {
        setDocData(response.results[0].data)
      }
    }
    fetchData()
  }, [])
  const history = useHistory()
  const gallery_urls = doc?.large_gallery?.map(obj => obj?.img?.url)

  const newLine = text => {
    return text && text.split('<br/>')
  }

  const chosen_cards = doc?.chosen_graded_cards?.map(obj => ({
    url: obj?.img?.url,
    grade: RichText.asText(obj?.grade),
    name: RichText.asText(obj?.name),
    price: RichText.asText(obj?.price)
  }))

  return (
    <Layout>
      <div className='slider-container'>
        {gallery_urls && (
          <Slide easing='ease'>
            {gallery_urls.map(url => (
              <div className='each-slide'>
                <div
                  style={{
                    backgroundImage: `url(${url})`
                  }}
                ></div>
              </div>
            ))}
          </Slide>
        )}
        <h3>
          {newLine(RichText.asText(doc?.large_gallery_description))?.[0]}
          <br />
          {newLine(RichText.asText(doc?.large_gallery_description))?.[1]}
        </h3>
      </div>
      <div className='half-blocks-container'>
        <div className='buy-graded-section'>
          <h3>{RichText.asText(doc?.graded_cards_title)}</h3>
          <SRLWrapper>
            {chosen_cards && (
              <Slide easing='ease'>
                {chosen_cards.map(obj => (
                  <div className='each-slide-2'>
                    <img
                      src={obj.url}
                      alt={`${obj?.name} | Grade: ${obj?.grade} ${obj?.price && '| $' + obj.price}`}
                    />
                  </div>
                ))}
              </Slide>
            )}
          </SRLWrapper>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className='primary-btn' onClick={() => history.push('/graded-cards/yugioh')}>
              {RichText.asText(doc?.graded_card_btn_text)}
            </Button>
          </div>
        </div>
        <div className='sell-section'>
          <h3>{RichText.asText(doc?.sell_us_cards_title)}</h3>
          <form action='https://formspree.io/f/xoqprvbn' method='POST' onSubmit={e => null}>
            <p>{RichText.asText(doc?.sell_us_cards_subheader)}</p>
            <span>Name: </span>
            <Input type='text' name='name' />
            <br />
            <span>Email: </span>
            <Input type='text' name='_replyto' />
            <br />
            <span>Message: </span>
            <Input type='text' name='message' />
            <Button className='primary-btn' type='submit'>
              {RichText.asText(doc?.sell_us_cards_btn_text)}
            </Button>
          </form>
        </div>
      </div>
      <div className='quarter-blocks-container'>
        <div className='pokemon'>
          <img src={doc?.products_section?.[0]?.logo?.url} />
          <div class='quarter-block-description'>
            {RichText.asText(doc?.products_section?.[0]?.description)}
            <p>
              Join our{' '}
              <a href='https://discord.gg/9FEJeDdDDu'>
                <DiscordIcon />
              </a>
              to learn more.
            </p>
          </div>
        </div>
        <div className='pops'>
          <img src={doc?.products_section?.[1]?.logo?.url} />
          <div class='quarter-block-description'>
            {RichText.asText(doc?.products_section?.[1]?.description)}
            <p>
              Join our{' '}
              <a href='https://discord.gg/9FEJeDdDDu'>
                <DiscordIcon />
              </a>
              to learn more.
            </p>
          </div>
        </div>

        <div className='yugioh'>
          <img src={logos.yugioh} />
          <div class='quarter-block-description'>
            {RichText.asText(doc?.products_section?.[2]?.description)}
            <p>
              Join our{' '}
              <a href='https://discord.gg/9FEJeDdDDu'>
                <DiscordIcon />
              </a>
              to learn more.
            </p>
          </div>
        </div>
        <div className='digimon'>
          <img src={doc?.products_section?.[3]?.logo?.url} />
          <div class='quarter-block-description'>
            {RichText.asText(doc?.products_section?.[3]?.description)}
            <p>
              Join our{' '}
              <a href='https://discord.gg/9FEJeDdDDu'>
                <DiscordIcon />
              </a>
              to learn more.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
