import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { SRLWrapper } from 'simple-react-lightbox'
import Layout from '../../templates/Layout'
import SearchIcon from '@material-ui/icons/Search'
import Gallery from 'react-grid-gallery'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

import Button from '../../atoms/Button'
import Input from '../../atoms/Input'

import './GalleryPage.styl'

const apiEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT
const accessToken = process.env.REACT_APP_PRISMIC_TOKEN

const Client = Prismic.client(apiEndpoint, { accessToken })

const GalleryPage = () => {
  const history = useHistory()
  const [showCTA, setShowCTA] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [trianglifyHeight, setTrianglifyHeight] = useState(0)

  const [doc, setDocData] = useState(null)

  useEffect(() => {
    const { href } = window.location
    let type = ''
    if (href.includes('japan')) {
      type = 'japanese-graded-cards'
    } else if (href.includes('topps')) {
      type = 'topps-graded-cards'
    } else if (href.includes('yugioh')) {
      type = 'yugioh-graded-cards'
    } else if (!href.includes('funko')) {
      type = 'pokemon-graded-cards'
    } else {
      type = 'funko-pop-gallery'
    }
    const fetchData = async () => {
      const response = await Client.query(Prismic.Predicates.at('document.type', type))
      if (response) {
        setDocData(response.results[0].data)
      }
    }
    fetchData()
  }, [])

  const getImgs = doc =>
    doc?.graded_cards?.map(obj => ({
      src: obj?.img?.url,
      thumbnail: obj?.img?.url,
      thumbnailWidth: !window.location.href.includes('funko') ? 200 : 300,
      thumbnailHeight: 350,
      name: RichText.asText(obj?.name)?.toLowerCase(),
      alt: `${RichText.asText(obj?.name)} | Grade: ${RichText.asText(obj?.grade)} ${
        obj?.price && '| $' + RichText.asText(obj?.price)
      } `
    }))

  useEffect(() => {
    setTimeout(() => {
      if (showCTA === false) document.querySelector('.gallery-cta').remove()
    }, 1000)
  }, [showCTA])

  useEffect(() => {
    const padding = trianglifyHeight
    const hero = document.querySelector('.gallery-page')
    const heroDimensions = hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: heroDimensions?.height + padding,
      cellSize: 50,
      variance: 0.4,
      xColors: 'Blues'
    })
    const x = document.querySelector('.gallery-page')
    if (x) {
      x.appendChild(pattern.toSVG())
    }

    window.onresize = () => resizePage()

    setTimeout(() => {
      resizePage()
    }, 500)
  }, [document.querySelector('.gallery-page')])

  const resizePage = () => {
    const $container = document.querySelector('.gallery-page__main')
    if (!$container) return
    const { offsetHeight, offsetWidth } = $container
    setTrianglifyHeight(offsetHeight)
    redrawSvg(offsetHeight)
  }

  const redrawSvg = height => {
    if (!window.trianglify) return
    const hero = document.querySelector('.gallery-page')
    const heroDimensions = hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: heroDimensions?.height + height,
      cellSize: 40,
      variance: 0.2,
      xColors: 'Blues'
    })
    const x = document.querySelector('.gallery-page svg')
    if (x) {
      document.querySelector('.gallery-page svg').remove()
    }
    const y = document.querySelector('.gallery-page')
    if (y) {
      document.querySelector('.gallery-page').appendChild(pattern.toSVG())
    }
    setTimeout(() => {
      const $footer = document.querySelector('.footer')
      if (!$footer) return
      $footer.style.position = `relative`
      $footer.style.top = `${height - window.innerHeight / 5}px`
    }, 1000)
  }

  return (
    <Layout>
      <div className='gallery-page' style={{ height: '100vh', width: '100vw' }}>
        <div className='search-box--container'>
          <Input
            type='text'
            onChange={e => setSearchText(e?.target?.value)}
            placeholder={RichText.asText(doc?.searchbox_placeholder_text)}
          />
        </div>
        <div className='gallery-page__main'>
          {getImgs(doc) && (
            <SRLWrapper>
              <Gallery
                images={getImgs(doc)?.filter(imgObj =>
                  imgObj.name.includes(searchText.toLowerCase())
                )}
                enableLightbox={false}
              />
            </SRLWrapper>
          )}
        </div>
        <div className={`gallery-cta ${!showCTA && 'fade-out'}`}>
          <span onClick={() => setShowCTA(false)}>X</span>
          <h3 style={{ marginBottom: '0rem' }}>{RichText.asText(doc?.cta_title)}</h3>
          <br />
          {RichText.asText(doc?.cta_text)}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className='primary-btn' onClick={() => history.push('/contact')}>
              {RichText.asText(doc?.cta_btn_text)}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GalleryPage
