import React from "react";
import { useHistory } from "react-router-dom";
import Prismic from "@prismicio/client";
import { RichText } from "prismic-reactjs";

import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import DiscordIcon from "../../atoms/DiscordIcon";
import Layout from "../../templates/Layout";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import "./CharityPage.styl";

const apiEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT;
const accessToken = process.env.REACT_APP_PRISMIC_TOKEN;

const Client = Prismic.client(apiEndpoint, { accessToken });

class CharityPage extends React.Component {
  state = {
    doc: null
  };

  fetchData = async () => {
    const response = await Client.query(Prismic.Predicates.at("document.type", "charity-page"));
    if (response) {
      this.setState({
        ...this.state,
        doc: response.results[0].data
      });
    }
  };

  componentDidMount() {
    this.fetchData();
    const mobile = window.innerWidth < 500;
    const tablet = !mobile && window.innerWidth < 1200;
    const hero = document.querySelector(".charity-page");
    const heroDimensions = hero?.getBoundingClientRect();
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: mobile
        ? 5000 + heroDimensions?.height
        : tablet
        ? 4200 + heroDimensions?.height
        : 3800 + heroDimensions?.height,
      cellSize: 50,
      variance: 0.4,
      xColors: "Blues"
    });
    const x = document.querySelector(".charity-page");
    if (x) {
      x.appendChild(pattern.toSVG());
    }

    window.onresize = () => {
      if (!window.trianglify) return;
      const hero = document.querySelector(".charity-page");
      const heroDimensions = hero?.getBoundingClientRect();
      const pattern = window?.trianglify({
        width: heroDimensions?.width,
        height: heroDimensions?.height + 300,
        cellSize: 40,
        variance: 0.2,
        xColors: "Blues"
      });
      const x = document.querySelector(".charity-page svg");
      if (x) {
        document.querySelector(".charity-page svg").remove();
      }
      const y = document.querySelector(".charity-page");
      if (y) {
        document.querySelector(".charity-page").appendChild(pattern.toSVG());
      }
    };

    setTimeout(() => {
      console.log("1");
      if (!window.trianglify) return;
      const hero = document.querySelector(".charity-page");
      const heroDimensions = hero?.getBoundingClientRect();
      const pattern = window?.trianglify({
        width: heroDimensions?.width,
        height: heroDimensions?.height + 300,
        cellSize: 40,
        variance: 0.2,
        xColors: "Blues"
      });
      const x = document.querySelector(".charity-page svg");
      if (x) {
        document.querySelector(".charity-page svg").remove();
      }
      const y = document.querySelector(".charity-page");
      if (y) {
        document.querySelector(".charity-page").appendChild(pattern.toSVG());
      }
    }, 2000);
  }

  render() {
    const { doc } = this.state;
    return (
      <Layout>
        <div className='charity-page'>
          <div className='charity-page__main'>
            {doc?.event_poster?.url && (
              <img src={doc.event_poster.url} alt='event poster' className='poster' />
            )}
            {doc?.event_title && <h1>{RichText.asText(doc.event_title)}</h1>}
            <ul>
              {doc?.date && (
                <li>
                  <strong>Date:</strong> {RichText.asText(doc.date)}
                </li>
              )}
              {doc?.time && (
                <li>
                  <strong>Time:</strong> {RichText.asText(doc.time)}
                </li>
              )}
              {doc?.location && (
                <li>
                  <strong>Location:</strong> {RichText.asText(doc.location)}
                </li>
              )}
              {doc?.charity && (
                <li>
                  <strong>Charity:</strong> {RichText.asText(doc.charity)}
                </li>
              )}
              {doc?.celebs && (
                <li>
                  <strong>Celebs:</strong> {RichText.asText(doc.celebs)}
                </li>
              )}
            </ul>
            {doc?.description && <p>{RichText.render(doc.description)}</p>}
            <br />
            {doc?.past_events && <h1>Past Events</h1>}
            {doc?.past_events?.length > 0 && (
              <div>
                {doc.past_events.map(
                  ({ poster, celebs1, charity1, title, location1, date1, amount_raised }) => (
                    <Card>
                      <CardActionArea>
                        <CardContent>
                          {title && (
                            <Typography gutterBottom variant='h5' component='h2'>
                              {RichText.asText(title)}
                            </Typography>
                          )}
                          <Typography variant='body2' color='textSecondary' component='p'>
                            {poster?.url && <img src={poster.url} alt='event poster' />}
                            <ul>
                              {date1 && (
                                <li>
                                  <strong>Date:</strong> {RichText.asText(date1)}
                                </li>
                              )}
                              {location1 && (
                                <li>
                                  <strong>Location:</strong> {RichText.asText(location1)}
                                </li>
                              )}
                              {charity1 && (
                                <li>
                                  <strong>Charity:</strong> {RichText.asText(charity1)}
                                </li>
                              )}
                              {celebs1 && (
                                <li>
                                  <strong>Celebs:</strong> {RichText.asText(celebs1)}
                                </li>
                              )}
                              {amount_raised && (
                                <li>
                                  <strong>Amount Raised:</strong> {RichText.asText(amount_raised)}
                                </li>
                              )}
                            </ul>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default CharityPage;
