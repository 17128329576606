import React from 'react'

import './CardIcon.styl'

export default () => (
  <svg
    height='512pt'
    viewBox='-66 0 512 512'
    width='512pt'
    xmlns='http://www.w3.org/2000/svg'
    className='icon--card'
  >
    <path fill='white' d='m320 0h-320v452h320zm-30 30v212h-260v-212zm-260 392v-150h260v150zm0 0' />
    <path fill='white' d='m350 60v422h-290v30h320v-452zm0 0' />
    <path fill='white' d='m60 362h170v30h-170zm0 0' />
    <path fill='white' d='m60 302h200v30h-200zm0 0' />
    <path
      fill='white'
      d='m142.675781 181.816406 21.214844 21.210938 21.210937-21.210938 28.285157 28.285156 21.214843-21.214843-28.285156-28.285157 21.210938-21.210937-21.210938-21.214844-21.214844 21.214844-78.488281-78.492187-21.214843 21.214843 78.492187 78.488281zm0 0'
    />
  </svg>
)
