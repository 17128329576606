import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { SRLWrapper } from 'simple-react-lightbox'
import Gallery from 'react-grid-gallery'
import SearchIcon from '@material-ui/icons/Search'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

import Layout from '../../templates/Layout'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import Button from '../../atoms/Button'
import Input from '../../atoms/Input'

import './VaultPage.styl'

const apiEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT
const accessToken = process.env.REACT_APP_PRISMIC_TOKEN

const Client = Prismic.client(apiEndpoint, { accessToken })

const VaultPage = () => {
  const history = useHistory()
  const [trianglifyHeight, setTrianglifyHeight] = useState(0)
  const [pageMode, setPageMode] = useState('list')
  const [selectedId, setSelectedId] = useState(null)
  const [selectedGallery, setSelectedGallery] = useState(null)
  const [searchText, setSearchText] = useState('')

  const [doc, setDocData] = useState(null)
  const [collectionData, setCollectionData] = useState(null)

  useEffect(() => {
    if (window.location.href.includes('vault')) {
      const fetchData = async () => {
        const response = await Client.query(Prismic.Predicates.at('document.type', 'vault-page'))
        if (response) {
          setDocData(response.results[0].data)
        }
      }
      const fetchDataCollections = async () => {
        const response = await Client.query(
          Prismic.Predicates.at('document.type', 'vault-collection')
        )
        if (response) {
          setCollectionData(response.results)
        }
      }
      fetchData()
      fetchDataCollections()
    }
    while (
      document.querySelector('vault-page__main + svg') &&
      document.querySelector('vault-page__main + svg + svg')
    ) {
      document.querySelector('vault-page__main + svg').remove()
    }
  }, [])

  useEffect(() => {
    if (pageMode === 'gallery') {
      const hero = document.querySelector('.vault-page__main')
      if (!hero) return
      const heroDimensions = hero && hero?.getBoundingClientRect()
      if (!heroDimensions) return

      const pattern = window?.trianglify({
        width: (heroDimensions && heroDimensions?.width) || 1,
        height: (heroDimensions && heroDimensions?.height / 2) || 1,
        cellSize: 50,
        variance: 0.4,
        xColors: 'Blues'
      })
      const x = document.querySelector('.vault-page')
      if (x) {
        x.appendChild(pattern.toSVG())
      }

      window.onresize = () => resizePage()

      setTimeout(() => {
        resizePage()
      }, 500)
      window.scrollTo(0, 0)
    }

    if (
      document.querySelector('vault-page__main + svg') &&
      document.querySelector('vault-page__main + svg + svg')
    ) {
      document.querySelector('vault-page__main + svg').remove()
    }
    while (
      document.querySelector('vault-page__main + svg') &&
      document.querySelector('vault-page__main + svg + svg')
    ) {
      document.querySelector('vault-page__main + svg').remove()
    }
  }, [pageMode])

  const getCollectionData = () => {
    if (!collectionData) return
    let ret = {}
    collectionData.forEach(coll => {
      ret[coll.id] = coll.data
    })
    return ret
  }

  useEffect(() => {
    const padding = trianglifyHeight
    const hero = document.querySelector('.vault-page__main')
    const heroDimensions = hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: heroDimensions?.height + padding,
      cellSize: 50,
      variance: 0.4,
      xColors: 'Blues'
    })
    const x = document.querySelector('.vault-page')
    if (x) {
      x.appendChild(pattern.toSVG())
    }

    window.onresize = () => resizePage()

    setTimeout(() => {
      resizePage()
    }, 500)
  }, [document.querySelector('.vault-page')])

  const resizePage = () => {
    const $container = document.querySelector('.vault-page__main')
    if (!$container) return
    const { offsetHeight, offsetWidth } = $container
    setTrianglifyHeight(offsetHeight)
    redrawSvg(offsetHeight)
  }

  const redrawSvg = height => {
    if (!window.trianglify) return
    const hero = document.querySelector('.vault-page')
    const heroDimensions = hero && hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions && heroDimensions?.width,
      height: heroDimensions && heroDimensions?.height + height,
      cellSize: 40,
      variance: 0.2,
      xColors: 'Blues'
    })
    const x = document.querySelector('.vault-page svg')
    if (x) {
      document.querySelector('.vault-page__main + svg').remove()
    }
    const y = document.querySelector('.vault-page')
    if (y) {
      document.querySelector('.vault-page').appendChild(pattern.toSVG())
    }
    setTimeout(() => {
      const $footer = document.querySelector('.footer')
      if (!$footer) return
      $footer.style.position = `relative`
      $footer.style.top = `${height - window.innerHeight / 5}px`
    }, 1000)
  }

  const extractGalleryInfo = collection => {
    return collection?.[1]?.gallery_items?.map(item => {
      const src = item?.img?.url
      const grade = RichText.asText(item?.grade)
      const name = RichText.asText(item?.card_title)
      const price = RichText.asText(item?.price)
      const description = RichText.asText(item?.card_description)
      return {
        src,
        grade,
        name,
        price,
        description,
        thumbnailWidth: 200,
        thumbnailHeight: 350,
        thumbnail: src,
        alt: `${name} | Grade: ${grade} ${price && '| $' + price} `
      }
    })
  }

  const deleteTrianglify = () => {
    const interval = setInterval(() => {
      while (
        document.querySelector('.vault-page__main + svg') &&
        document.querySelector('.vault-page__main + svg + svg')
      ) {
        document.querySelector('.vault-page__main + svg').remove()
      }
    }, 1000)
    setTimeout(() => {
      clearInterval(interval)
    }, 5000)
  }

  return (
    <Layout>
      <div className='vault-page' style={{ height: '100vh', width: '100vw' }}>
        {/* {pageMode === 'gallery' && (
          <div className='search-box--container'>
            <Input
              type='text'
              onChange={e => setSearchText(e?.target?.value)}
              placeholder={RichText.asText(doc?.searchbox_placeholder_text)}
            />
          </div>
        )} */}
        <div className={`vault-page__main ${pageMode}`}>
          {pageMode === 'list' ? (
            <Fragment>
              <div className='vault-page__main__header'>
                <img src={doc?.vault_image?.url} />
                <h1>{RichText.asText(doc?.title)}</h1>
                <p>{RichText.asText(doc?.page_description)}</p>
              </div>
              {getCollectionData() &&
                Object.entries(getCollectionData()).map(obj => {
                  return (
                    <div
                      className='collection-card'
                      onClick={() => {
                        setSelectedId(obj[0])
                        setSelectedGallery(RichText.asText(obj?.[1]?.title))
                        setPageMode('gallery')
                        deleteTrianglify()
                      }}
                    >
                      <img src={obj?.[1]?.gallery_banner?.url} />
                      <h3>{RichText.asText(obj?.[1]?.title)}</h3>
                      <p>{RichText.asText(obj?.[1]?.gallery_description)}</p>
                    </div>
                  )
                })}
            </Fragment>
          ) : (
            <Fragment>
              <button onClick={() => window.location.reload()}>
                <ChevronLeftIcon />
                Back to Vault
              </button>
              {selectedGallery && <h1>{selectedGallery}</h1>}
              {getCollectionData() && (
                <SRLWrapper>
                  <div className='photo-container'>
                    {getCollectionData() && (
                      <Gallery
                        images={Object.entries(getCollectionData())
                          .filter(obj => obj[0] === selectedId)
                          .map(coll => extractGalleryInfo(coll))[0]
                          .filter(
                            obj =>
                              obj.name && obj.name.toLowerCase().includes(searchText.toLowerCase())
                          )}
                        enableLightbox={false}
                      />
                    )}
                  </div>
                </SRLWrapper>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default VaultPage
