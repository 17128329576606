import React, { Fragment } from 'react'

import NavBar from '../../organisms/Navbar'
import Footer from '../../organisms/Footer'

import './Layout.styl'

const Layout = ({ children, navbarProps, ...props }) => (
  <Fragment>
    <NavBar {...navbarProps} />
    {children}
    <Footer />
  </Fragment>
)

export default Layout
