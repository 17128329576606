import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { SRLWrapper } from 'simple-react-lightbox'
import Layout from '../../templates/Layout'
import SearchIcon from '@material-ui/icons/Search'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

import Button from '../../atoms/Button'
import Input from '../../atoms/Input'

import './WishlistPage.styl'

const apiEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT
const accessToken = process.env.REACT_APP_PRISMIC_TOKEN

const Client = Prismic.client(apiEndpoint, { accessToken })

const WishlistPage = () => {
  const history = useHistory()
  const [showCTA, setShowCTA] = useState(true)
  const [trianglifyHeight, setTrianglifyHeight] = useState(0)

  const [doc, setDocData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(Prismic.Predicates.at('document.type', 'wishlist-page'))
      if (response) {
        setDocData(response.results[0].data)
      }
    }
    fetchData()
  }, [])

  const getImgs = doc =>
    doc?.graded_cards?.map(obj => ({
      src: obj?.img?.url,
      alt: `${RichText.asText(obj?.description)}`
    }))

  useEffect(() => {
    setTimeout(() => {
      if (showCTA === false) document.querySelector('.wishlist-cta').remove()
    }, 1000)
  }, [showCTA])

  useEffect(() => {
    const padding = trianglifyHeight
    const hero = document.querySelector('.wishlist-page')
    const heroDimensions = hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: heroDimensions?.height + padding,
      cellSize: 50,
      variance: 0.4,
      xColors: 'Blues'
    })
    const x = document.querySelector('.wishlist-page')
    if (x) {
      x.appendChild(pattern.toSVG())
    }

    window.onresize = () => resizePage()

    setTimeout(() => {
      resizePage()
    }, 500)
  }, [document.querySelector('.wishlist-page')])

  const resizePage = () => {
    const $container = document.querySelector('.wishlist-page__main')
    if (!$container) return
    const { offsetHeight, offsetWidth } = $container
    setTrianglifyHeight(offsetHeight)
    redrawSvg(offsetHeight)
  }

  const redrawSvg = height => {
    if (!window.trianglify) return
    const hero = document.querySelector('.wishlist-page')
    const heroDimensions = hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: heroDimensions?.height + height,
      cellSize: 40,
      variance: 0.2,
      xColors: 'Blues'
    })
    const x = document.querySelector('.wishlist-page svg')
    if (x) {
      document.querySelector('.wishlist-page svg').remove()
    }
    const y = document.querySelector('.wishlist-page')
    if (y) {
      document.querySelector('.wishlist-page').appendChild(pattern.toSVG())
    }
    setTimeout(() => {
      const $footer = document.querySelector('.footer')
      if (!$footer) return
      $footer.style.position = `relative`
      $footer.style.top = `${height - window.innerHeight / 5}px`
    }, 1000)
  }

  return (
    <Layout>
      <div className='wishlist-page' style={{ height: '100vh', width: '100vw' }}>
        <div className='wishlist-page__main wishlist-page__main'>
          {getImgs(doc) && (
            <SRLWrapper>
              <div className='photo-container'>
                {[...getImgs(doc)].map(obj => (
                  <div>
                    <div>
                      <p>{obj?.alt}</p>
                    </div>
                    <img src={obj?.src} alt={obj?.alt} key={obj?.src} />
                  </div>
                ))}
              </div>
            </SRLWrapper>
          )}
        </div>
        <div className={`wishlist-cta ${!showCTA && 'fade-out'}`}>
          <span onClick={() => setShowCTA(false)}>X</span>
          <h3 style={{ marginBottom: '0rem' }}>{RichText.asText(doc?.cta_title)}</h3>
          <br />
          {RichText.asText(doc?.cta_text)}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className='primary-btn' onClick={() => history.push('/contact')}>
              {RichText.asText(doc?.cta_btn_text)}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WishlistPage
