import React from 'react'

import './Button.styl'

const Button = ({ className, children, onClick, ...props }) => (
  <button className={`button ${className || ''}`} onClick={onClick}>
    {children}
  </button>
)

export default Button
