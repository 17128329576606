import React from 'react'
import { useHistory } from 'react-router-dom'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

import Button from '../../atoms/Button'
import Input from '../../atoms/Input'
import DiscordIcon from '../../atoms/DiscordIcon'
import Layout from '../../templates/Layout'

import BgImg from '../../assets/img/bg2.png'
import RatingImg from '../../assets/img/rating.png'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import './CalendarPage.styl'

const apiEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT
const accessToken = process.env.REACT_APP_PRISMIC_TOKEN

const Client = Prismic.client(apiEndpoint, { accessToken })

class CalendarPage extends React.Component {
  state = {
    doc: null
  }
  fetchData = async () => {
    const response = await Client.query(Prismic.Predicates.at('document.type', 'calendar-page'))
    if (response) {
      this.setState({
        ...this.state,
        doc: response.results[0].data
      })
    }
  }
  componentDidMount() {
    this.fetchData()
    const hero = document.querySelector('.calendar-page')
    const heroDimensions = hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: heroDimensions?.height + (window.innerWidth < 550 ? 1400 : 900),
      cellSize: 50,
      variance: 0.4,
      xColors: 'Blues'
    })
    const x = document.querySelector('.calendar-page')
    if (x) {
      x.appendChild(pattern.toSVG())
    }

    window.onresize = () => {
      if (!window.trianglify) return
      const hero = document.querySelector('.calendar-page')
      const heroDimensions = hero?.getBoundingClientRect()
      const pattern = window?.trianglify({
        width: heroDimensions?.width,
        height: heroDimensions?.height + (window.innerWidth < 550 ? 1400 : 900),
        cellSize: 40,
        variance: 0.2,
        xColors: 'Blues'
      })
      const x = document.querySelector('.calendar-page svg')
      if (x) {
        document.querySelector('.calendar-page svg').remove()
      }
      const y = document.querySelector('.calendar-page')
      if (y) {
        document.querySelector('.calendar-page').appendChild(pattern.toSVG())
      }
    }
  }

  render() {
    const { doc } = this.state
    return (
      <Layout>
        <div className='calendar-page' style={{ height: '100vh', width: '100vw' }}>
          <div className='calendar-page__main'>
            <h1>{RichText.asText(doc?.title)}</h1>
            <h5>{RichText.asText(doc?.subheader)}</h5>
            <p>
              {RichText.asText(doc?.description)}&nbsp; The best way to stay in the loop, is to join
              our{' '}
              <a href='https://discord.gg/9FEJeDdDDu'>
                <DiscordIcon />
              </a>
              but here is our general schedule:
            </p>
            <br />
            <h2>{RichText.asText(doc?.group_one_title)}</h2>
            <Table aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <TableCell width='50%'>{RichText.asText(doc?.group_one_left_column)}</TableCell>
                  <TableCell align='left' width='25%'>
                    {RichText.asText(doc?.group_one_middle_column)}
                  </TableCell>
                  <TableCell align='left' width='25%'>
                    {RichText.asText(doc?.group_one_right_column)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doc?.group_one_entry?.map(row => (
                  <TableRow key={RichText.asText(row.left)}>
                    <TableCell component='th' scope='row' width='50%'>
                      {RichText.asText(row?.left)}
                    </TableCell>
                    <TableCell align='left' width='25%'>
                      {' '}
                      {RichText.asText(row?.middle)}
                    </TableCell>
                    <TableCell align='left' width='25%'>
                      {' '}
                      {RichText.asText(row?.right)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <br />
            <h2>{RichText.asText(doc?.group_two_title)}</h2>
            <Table aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <TableCell width='50%'>{RichText.asText(doc?.group_two_left_column)}</TableCell>
                  <TableCell align='left' width='25%'>
                    {RichText.asText(doc?.group_two_middle_column)}
                  </TableCell>
                  <TableCell align='left' width='25%'>
                    {RichText.asText(doc?.group_two_right_column)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doc?.group_two?.map(row => (
                  <TableRow key={RichText.asText(row?.left)}>
                    <TableCell component='th' scope='row' width='50%'>
                      {RichText.asText(row?.left)}{' '}
                    </TableCell>
                    <TableCell align='left' width='25%'>
                      {' '}
                      {RichText.asText(row?.middle)}
                    </TableCell>
                    <TableCell align='left' width='25%'>
                      {' '}
                      {RichText.asText(row?.right)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <br />
            {RichText.asText(doc?.group_three_title)?.length > 1 && (
              <>
                <h2>{RichText.asText(doc?.group_three_title)}</h2>
                <Table aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <TableCell width='50%'>
                        {RichText.asText(doc?.group_three_left_column)}
                      </TableCell>
                      <TableCell align='right' width='25%'>
                        {RichText.asText(doc?.group_three_middle_column)}
                      </TableCell>
                      <TableCell align='right' width='25%'>
                        {RichText.asText(doc?.group_three_right_column)}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {doc?.group_three?.map(row => (
                      <TableRow key={RichText.asText(row?.left)}>
                        <TableCell component='th' scope='row' width='50%'>
                          {RichText.asText(row?.left)}
                        </TableCell>
                        <TableCell align='left' width='25%'>
                          {' '}
                          {RichText.asText(row?.middle)}
                        </TableCell>
                        <TableCell align='left' width='25%'>
                          {' '}
                          {RichText.asText(row?.right)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default CalendarPage
