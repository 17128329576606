import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import GradeIcon from "@material-ui/icons/Grade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import CardIcon from "./../../atoms/CardIcon";
import WishlistIcon from "./../../atoms/WishlistIcon";

import "./Navbar.styl";
import RMCLogo from "../../assets/img/cropped-rmc-logo-2.jpg";

const Navbar = (props) => {
  const [drawerState, setDrawerState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!anchorEl2) return;
    setTimeout(() => {
      const popover = document.querySelector(".MuiPaper-root.MuiMenu-paper");
      if (!popover) return;
      popover.setAttribute("style", "left: calc(100vw - 460px) !important");
    }, 10);
  }, [anchorEl2]);

  return (
    <AppBar>
      <Toolbar>
        <img src={RMCLogo} onClick={() => (window.location.href = "/")} />
        <h3 onClick={() => (window.location.href = "/")}>Rocky Mountain Collectibles</h3>
        <Hidden smDown>
          <div className='tabs'>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              onClick={() => setAnchorEl(true)}
            >
              <CardIcon />
              <h5>Galleries</h5>
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(false)}>
              <MenuItem onClick={() => history.push("/graded-cards/yugioh")}>Yugioh</MenuItem>
              <MenuItem onClick={() => history.push("/graded-cards/pokemon")}>Pokemon</MenuItem>
              <MenuItem onClick={() => history.push("/graded-cards/topps-pokemon")}>
                Topps Pokemon
              </MenuItem>
              <MenuItem onClick={() => history.push("/graded-cards/japanese-pokemon")}>
                Japanese Pokemon
              </MenuItem>
              <MenuItem onClick={() => history.push("/funko-pop")}>Funko POP</MenuItem>
            </Menu>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              onClick={() => history.push("/sell")}
            >
              <AttachMoneyIcon />
              <h5>Sell Product</h5>
            </IconButton>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              onClick={() => setAnchorEl2(true)}
            >
              <DateRangeIcon />
              <h5>Events</h5>
            </IconButton>
            <Menu
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={() => setAnchorEl2(false)}
            >
              <MenuItem onClick={() => history.push("/charity")}>Charity</MenuItem>
              <MenuItem onClick={() => history.push("/calendar")}>Weekly Tournaments</MenuItem>
            </Menu>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              onClick={() => history.push("/contact")}
            >
              <ContactMailIcon />
              <h5>Contact</h5>
            </IconButton>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              onClick={() => history.push("/wishlist")}
            >
              <WishlistIcon />
              <h5>Wishlist</h5>
            </IconButton>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              onClick={() => history.push("/vault")}
            >
              <LockOpenIcon />
              <h5>Vault</h5>
            </IconButton>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className='tabs'>
            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              onClick={() => setDrawerState(true)}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <Drawer
            variant='temporary'
            anchor={"right"}
            open={drawerState}
            onClose={() => setDrawerState(false)}
            ModalProps={{
              keepMounted: true
            }}
          >
            <div className='tabs'>
              <IconButton onClick={() => setDrawerState(false)}>
                <ChevronRightIcon />
              </IconButton>
              <List>
                <ListItem button>
                  <ListItemIcon>
                    <GradeIcon />
                  </ListItemIcon>
                  <ListItemText primary='Galleries' />
                </ListItem>
                <div style={{ paddingLeft: "25px" }}>
                  <ListItem button onClick={() => history.push("/graded-cards/yugioh")}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary='Yugioh' />
                  </ListItem>
                  <ListItem button onClick={() => history.push("/graded-cards/pokemon")}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary='Pokemon' />
                  </ListItem>
                  <ListItem button onClick={() => history.push("/graded-cards/topps-pokemon")}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary='Topps Pokemon' />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                      primary='Japanese Pokemon'
                      onClick={() => history.push("/graded-cards/japanese-pokemon")}
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary='Funko POP' onClick={() => history.push("/funko-pop")} />
                  </ListItem>
                </div>

                <ListItem button onClick={() => history.push("/sell")}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary='Sell Product' onClick={() => history.push("/sell")} />
                </ListItem>
                <div style={{ paddingLeft: "25px" }}>
                  <ListItem button onClick={() => history.push("/calendar")}>
                    <ListItemIcon>
                      <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText primary='Calendar' onClick={() => history.push("/calendar")} />
                  </ListItem>
                  <ListItem button onClick={() => history.push("/charity")}>
                    <ListItemIcon>
                      <FavoriteIcon />
                    </ListItemIcon>
                    <ListItemText primary='Charity' onClick={() => history.push("/calendar")} />
                  </ListItem>
                </div>
                <ListItem button onClick={() => history.push("/contact")}>
                  <ListItemIcon>
                    <ContactMailIcon />
                  </ListItemIcon>
                  <ListItemText primary='Contact' onClick={() => history.push("/contact")} />
                </ListItem>
                <ListItem button onClick={() => history.push("/wishlist")}>
                  <ListItemIcon>
                    <WishlistIcon />
                  </ListItemIcon>
                  <ListItemText primary='Wishlist' onClick={() => history.push("/wishlist")} />
                </ListItem>
                <ListItem button onClick={() => history.push("/vault")}>
                  <ListItemIcon>
                    <LockOpenIcon />
                  </ListItemIcon>
                  <ListItemText primary='Vault' onClick={() => history.push("/vault")} />
                </ListItem>
              </List>
            </div>
          </Drawer>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
