import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";

import HomePage from "./pages/HomePage";
import GalleryPage from "./pages/GalleryPage";
import SellPage from "./pages/SellPage";
import CalendarPage from "./pages/CalendarPage";
import ContactPage from "./pages/ContactPage";
import WishlistPage from "./pages/WishlistPage";
import VaultPage from "./pages/VaultPage";
import CharityPage from "./pages/CharityPage";

import "./App.styl";

function App() {
  return (
    <Router>
      <Route exact path='/'>
        <SimpleReactLightbox>
          <HomePage />
        </SimpleReactLightbox>
      </Route>
      <Route exact path='/graded-cards/yugioh'>
        <SimpleReactLightbox>
          <GalleryPage />
        </SimpleReactLightbox>
      </Route>
      <Route exact path='/graded-cards/pokemon'>
        <SimpleReactLightbox>
          <GalleryPage />
        </SimpleReactLightbox>
      </Route>
      <Route exact path='/graded-cards/topps-pokemon'>
        <SimpleReactLightbox>
          <GalleryPage />
        </SimpleReactLightbox>
      </Route>
      <Route exact path='/graded-cards/japanese-pokemon'>
        <SimpleReactLightbox>
          <GalleryPage />
        </SimpleReactLightbox>
      </Route>
      <Route exact path='/funko-pop'>
        <SimpleReactLightbox>
          <GalleryPage />
        </SimpleReactLightbox>
      </Route>
      <Route exact path='/sell'>
        <SellPage />
      </Route>
      <Route exact path='/calendar'>
        <CalendarPage />
      </Route>
      <Route exact path='/contact'>
        <ContactPage />
      </Route>
      <Route exact path='/wishlist'>
        <SimpleReactLightbox>
          <WishlistPage />
        </SimpleReactLightbox>
      </Route>
      <Route exact path='/vault'>
        <SimpleReactLightbox>
          <VaultPage />
        </SimpleReactLightbox>
      </Route>
      <Route exact path='/charity'>
        <SimpleReactLightbox>
          <CharityPage />
        </SimpleReactLightbox>
      </Route>
    </Router>
  );
}

export default App;
