import React from 'react'
import { useHistory } from 'react-router-dom'
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'

import Button from '../../atoms/Button'
import Input from '../../atoms/Input'
import DiscordIcon from '../../atoms/DiscordIcon'
import Layout from '../../templates/Layout'

import './ContactPage.styl'

const apiEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT
const accessToken = process.env.REACT_APP_PRISMIC_TOKEN

const Client = Prismic.client(apiEndpoint, { accessToken })

class ContactPage extends React.Component {
  state = {
    doc: null
  }
  fetchData = async () => {
    const response = await Client.query(Prismic.Predicates.at('document.type', 'contact-page'))
    if (response) {
      this.setState({
        ...this.state,
        doc: response.results[0].data
      })
    }
  }
  componentDidMount() {
    this.fetchData()
    const hero = document.querySelector('.contact-page')
    const heroDimensions = hero?.getBoundingClientRect()
    const pattern = window?.trianglify({
      width: heroDimensions?.width,
      height: heroDimensions?.height + 300,
      cellSize: 50,
      variance: 0.4,
      xColors: 'Blues'
    })
    const x = document.querySelector('.contact-page')
    if (x) {
      x.appendChild(pattern.toSVG())
    }

    window.onresize = () => {
      if (!window.trianglify) return
      const hero = document.querySelector('.contact-page')
      const heroDimensions = hero?.getBoundingClientRect()
      const pattern = window?.trianglify({
        width: heroDimensions?.width,
        height: heroDimensions?.height + 300,
        cellSize: 40,
        variance: 0.2,
        xColors: 'Blues'
      })
      const x = document.querySelector('.contact-page svg')
      if (x) {
        document.querySelector('.contact-page svg').remove()
      }
      const y = document.querySelector('.contact-page')
      if (y) {
        document.querySelector('.contact-page').appendChild(pattern.toSVG())
      }
    }
  }

  render() {
    const { doc } = this.state
    return (
      <Layout>
        <div className='contact-page'>
          <div className='contact-page__main'>
            <h1>{RichText.asText(doc?.title)}</h1>
            <h5>{RichText.asText(doc?.subheader)}</h5>
            <div class='contact-page__main__iframe'>
              <iframe
                width='647'
                height='500'
                id='gmap_canvas'
                src='https://maps.google.com/maps?q=4895%20Joliet%20St,%20Denver,%20CO%2080239&t=&z=13&ie=UTF8&iwloc=&output=embed'
                frameborder='0'
                scrolling='no'
                marginheight='0'
                marginwidth='0'
              />
            </div>
            <form action='https://formspree.io/f/xoqprvbn' method='POST' onSubmit={e => null}>
              <span>Name </span> <br />
              <Input type='text' name='name' />
              <br />
              <span>Email </span> <br />
              <Input type='text' name='_replyto' />
              <br />
              <span>Message </span> <br />
              <Input type='text' name='message' />
              <br />
              <Button className='primary-btn' type='submit'>
                {RichText.asText(doc?.btn_text)}
              </Button>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactPage
